<template>
  <a class="logout__link btns" href="#" v-on:click="logout" title="Logout">
    <svg class="icon i24x24">
      <use xlink:href="#i-logout"></use>
    </svg>
  </a>
</template>

<script>
import useAdmin from "@/helpers/useAdmin";

export default {
  name: 'logout-header-link',
  setup() {
    const {logout} = useAdmin();

    return {logout};
  }
};
</script>
