<template>
  <header-line></header-line>
  <div class="content" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}" v-if="data">
    <div class="site-width">
      <section class="vouchers">
        <div class="vouchers__tabs">
          <ul class="tabs__menu">
            <li class="tabs__item ui-tabs-active">
              <router-link class="tabs__link" v-bind:to="{name: 'home', params: {lang}}">
                {{ t('label.home') }}
              </router-link>
            </li>
            <li class="tabs__item">
              <router-link class="tabs__link" v-bind:to="{name: 'shop', params: {lang}}">
                {{ t('label.shop') }}
              </router-link>
            </li>
            <li class="tabs__item">
              <router-link class="tabs__link" v-bind:to="{name: 'about', params: {lang}}">
                {{ t('label.about') }}
              </router-link>
            </li>
          </ul>
          <div class="tabs__content">
            <div class="tabs__item" id="all">

              <div class="tabs__section">
                <div class="vouchers__list card__list">
                  <div class="article__content article__content--home richtext" v-html="data.homepageContent"></div>

                  <div
                    v-if="data.homepageRetailOptions && retailOptions.length"
                    class="option-items-container option-items-container--home"
                  >
                    <a v-for="retOption in retailOptions" class="option-item option-item--home"
                       @click="selectOption(retOption)"
                    >
                      <div class="option-img-wrapper" v-if="retOption.image"
                           :style="{'background-image': `url('${retOption.image.src}')`}"
                      ></div>
                      <h5 v-if="retOption.name">{{ retOption.name }}</h5>
                    </a>
                  </div>


                  <div class="options-popup" v-if="activeSelectOption">
                    <div class="options-popup-overlay" @click="activeSelectOption = null"></div>
                    <div class="options-popup-inner">
                      <div class="options-popup-inner-content">
                        <div class="options-popup-inner-content-inner">
                          <button
                            @click="activeSelectOption = null"
                            class="bnts btn-close"
                            title="close"
                          ><i class="icons i24x24 i-close"></i></button>
                          <h3 class="popup-header">{{ t('label.select_option') }}</h3>

                          <div class="card" style="width: 100%; margin-bottom: 50px">
                            <div class="card__image">
                              <img v-if="activeSelectOption.image" v-bind:src="activeSelectOption.image.src" v-bind:alt="activeSelectOption.name" />
                            </div>
                            <div class="card__block">
                              <div class="card__label" />
                              <h1 class="card__title">{{ activeSelectOption.name }}</h1>
                              <div class="card__description"
                                   v-html="activeSelectOption.description"
                                   v-if="activeSelectOption.description" />

                              <div class="card__options">
                                <div style="margin-bottom: 24px" v-if="activeSelectOption.products.length > 1">
                                  <voucher-custom-select
                                    v-bind:label="data.popupLabelOptions || t('label.options')"
                                    name="popup-product"
                                    v-model:value="popupProduct"
                                    v-bind:items="getOptionProductSelect(activeSelectOption)"
                                  ></voucher-custom-select>
                                </div>
                                <voucher-custom-select
                                  v-bind:label="data.popupLabelQuantity || t('label.quantity')"
                                  name="popup-qty"
                                  v-model:value="popupQty"
                                  v-bind:items="getQuantityOptions(popupProduct)"
                                ></voucher-custom-select>
                              </div>

                              <div class="card__bottom" style="margin-top: 48px">
                                <div class="card__price"></div>
                                <div class="card__btns">
                                  <button
                                    class="btns btn-orange" v-bind:class="{disabled: false}"
                                    v-on:click.prevent="popupOnConfirm"
                                  >
                                    {{ t('button.confirm') }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="shop-btn-container" v-if="data.shopButtonText">
                    <router-link v-bind:to="{name: 'shop', params: {lang}}" class="btns btn-shop">
                      {{ data.shopButtonText }}
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="tabs__section" style="position: relative" v-if="vouchers.length > 2">
                <h3 v-if="data.homepageCarouselTitle" class="featured-title">{{ data.homepageCarouselTitle }}</h3>
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="product in vouchers" v-bind:key="product.id">
                      <div class="card card--small" style="min-height: 220px">
                        <product-list-item v-bind:product="product" :hide-add-to-card="true" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
              <div class="tabs__section" style="position: relative" v-else>
                <h3 v-if="data.homepageCarouselTitle" class="featured-title">{{ data.homepageCarouselTitle }}</h3>
                <div class="vouchers__list card__list">
                  <div class="card card--small" style="min-height: 220px"
                       v-for="product in vouchers" v-bind:key="product.id">
                    <product-list-item v-bind:product="product" :hide-add-to-card="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <footer-line></footer-line>
</template>

<script>
  import HeaderLine from "@/components/HeaderLine";
  import FooterLine from "@/components/FooterLine";
  import ProductListItem from "@/components/ProductListItem";
  import VoucherCustomSelect from "@/components/VoucherCustomSelect";
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import { useI18n } from "vue-i18n";
  import { useRoute, useRouter } from "vue-router";
  import product from "@/pages/Product.vue";
  import { createToaster } from "@meforma/vue-toaster";
  import { MUTATION_TYPES } from "@/constants";

  export default {
    name: 'home',
    methods: {
      product() {
        return product
      }
    },
    components: { FooterLine, HeaderLine, ProductListItem, VoucherCustomSelect },
    mounted: function () {
      import('swiper/bundle').then((lib) => {
        new lib.default('.swiper-container', {
          roundLengths: true,
          speed: 600,
          loop: true,
          slidesPerView: 1,
          effect: 'coverflow',
          spaceBetween: 0,
          coverflowEffect: {
            rotate: -20,
            slideShadows: false,
          },
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            768: {
              spaceBetween: 10,
              slidesPerView: 2,
            },
          }
        });
      });
    },
    setup() {
      const store = useStore();
      const { t } = useI18n();
      const router = useRouter();
      const route = useRoute();
      if (window.location.href.indexOf('iframed') !== -1 || (window.localStorage.getItem('iframed') === '1')) {
        router.push({ name: 'shop', params: route.params });
        return;
      }
      const toaster = createToaster();

      const selectedOptions = computed(() => store.getters.selectedOptions.options);
      const selectedOptionsId = computed(() => store.getters.selectedOptions.productId);

      const popupProduct = ref(null);
      const popupQty = ref(1);
      const activeSelectOption = ref(null);
      const selectOption = (selectedOption) => {
        if (selectedOptionsId.value) {
          const activeProduct = selectedOption.products.find(p => p.id == selectedOptionsId.value);
          if (activeProduct && activeProduct.id == selectedOptionsId.value) {
            const selectedCount = Object.values(selectedOptions.value).length;
            if (selectedCount >= (activeProduct.multipleOptions || 1)) {
              toaster.error(t('notification.box_is_full'));
            } else {
              store.commit(MUTATION_TYPES.setProductOption, {
                productId: activeProduct.id,
                option: { ...selectedOption, products: undefined },
              });
              toaster.success(t('notification.item_added', { name: selectedOption.name }));
            }

            router.push({ name: 'product', params: { ...route.params, id: activeProduct.id, lang } });
            return;
          }
        }

        activeSelectOption.value = selectedOption;
        popupProduct.value = selectedOption.products[0].id;
        popupQty.value = 1;
      };
      const popupOnConfirm = () => {
        for (let i = 1; i <= popupQty.value; i++) {
          store.commit(MUTATION_TYPES.setProductOption, {
            productId: popupProduct.value,
            index: i,
            option: { ...activeSelectOption.value, products: undefined },
          });
        }
        toaster.success(t('notification.item_added', { name: activeSelectOption.value.name }));

        router.push({ name: 'product', params: { ...route.params, id: popupProduct.value, lang } })
      };

      const vouchers = computed(() => store.getters.featuredProducts);
      const data = computed(() => store.getters.data);
      const retailOptions = computed(() => {
        const productsWithOptions = store.getters.data.vouchers.filter(el => !!el.retailOptions && el.retailOptions.length > 0);
        const options = productsWithOptions.map((el) => el.retailOptions).flat()
          .filter((el, i, a) => a.findIndex((opt) => opt.id === el.id) === i); // unique options
        let newOptions = [];
        options.forEach((opt) => {
          newOptions.push({
            ...opt,
            products: productsWithOptions.filter(prod => !!prod.retailOptions.find((prodOpt) => prodOpt.id === opt.id)),
          });
        });
        return newOptions;
      });

      return {
        vouchers, data, t, popupOnConfirm,
        retailOptions, selectOption, activeSelectOption, popupProduct, popupQty,
        getQuantityOptions: (productId) => {
          const selectedProduct = vouchers.value.find((prod) => prod.id == productId);
          const maxQty = selectedProduct && selectedProduct.multipleOptions || 1;
          let opts = {};
          for (let i = 1; i <= maxQty; i++) {
            opts[i] = i;
          }
          return opts;
        },
        getOptionProductSelect: (option) => {
          let opts = {};
          for (let i = 0; i < option.products.length; i++) {
            opts[option.products[i].id] = option.products[i].name;
          }
          return opts;
        },
      };
    }
  }
</script>

<style>
  .featured-title {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 40px;
  }
</style>