import {computed} from 'vue'
import {useStore} from 'vuex'
import {ACTION_TYPES} from "@/constants";
import { useRoute } from "vue-router";

export default function () {
	const store = useStore();
	const admin = computed(() => store.getters.admin);
	const adminLoaded = computed(() => store.getters.isAdminLoaded);
	const adminLoading = computed(() => store.getters.isAdminLoading);
	const route = useRoute();

	const redirectIfNotLoggedIn = (router) => {
		if (!adminLoading.value && adminLoaded.value && adminLoaded.value && !admin.value) {
			router.push({name: 'admin_login', params: route.params});
		}
	}
	const logout = () => store.dispatch(ACTION_TYPES.logout);

	const redirectIfLoggedIn = (router) => {
		if (adminLoaded.value && admin.value) {
			router.push({name: 'admin_search', params: route.params});
		}
	}

	return {
		admin,
		adminLoaded,
		redirectIfNotLoggedIn,
		redirectIfLoggedIn,
		logout
	}
}