<template>
  <header-line></header-line>
  <div class="content" v-if="voucher" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width">
      <div class="vouchers__tabs">
        <ul class="tabs__menu">
          <li class="tabs__item">
            <router-link class="tabs__link" v-bind:to="{name: 'home', params: {lang}}">
              {{ t('label.home') }}
            </router-link>
          </li>
          <li class="tabs__item ui-tabs-active">
            <router-link class="tabs__link" v-bind:to="{name: 'shop', params: {lang}}">
              {{ t('label.shop') }}
            </router-link>
          </li>
          <li class="tabs__item">
            <router-link class="tabs__link" v-bind:to="{name: 'about', params: {lang}}">
              {{ t('label.about') }}
            </router-link>
          </li>
        </ul>
      </div>
      <section class="voucher__page">
        <div class="card">
          <div class="card__image">
            <img v-if="voucher.image_3x" v-bind:src="voucher.image_3x.src" v-bind:alt="voucher.name" />
          </div>
          <div class="card__block">
            <div class="card__label">{{ isRetail ? t('label.product') : t('label.voucher') }}</div>
            <h1 class="card__title">{{ voucher.name }}</h1>
            <div class="card__description" v-html="voucher.description"></div>


            <div class="card__options" v-if="voucher.priceType === 'VARIANT'">
              <voucher-custom-select
                v-bind:label="t('label.options')"
                name="option"
                v-model:value="option"
                v-bind:value="getSelectOptions(voucher)[option] || ''"
                v-bind:items="getSelectOptions(voucher)"
              ></voucher-custom-select>
            </div>
            <div class="card__options" v-if="voucher.priceType === 'FIXED' && voucher.multipleOptions > 1">
              <div class="card__label">{{ t('label.select_x_options', { count: voucher.multipleOptions }) }}</div>

              <div class="option-items-container">
                <a v-for="num in voucher.multipleOptions" class="option-item"
                   :class="{'centered': !selectedOptions[num]}"
                   @click="activeSelectOptionPopupNumber = num"
                >
                  <div class="option-img-wrapper" v-if="selectedOptions[num] && selectedOptions[num].image"
                       :style="{'background-image': `url('${selectedOptions[num].image.src}')`}"
                  ></div>
                  <h5 v-if="selectedOptions[num] && selectedOptions[num].name">{{ selectedOptions[num].name }}</h5>

                  <div class="option-plus-sign" v-if="!selectedOptions[num]">+</div>
                </a>
              </div>

              <div class="options-popup" v-if="activeSelectOptionPopupNumber">
                <div class="options-popup-overlay" @click="activeSelectOptionPopupNumber = null"></div>
                <div class="options-popup-inner">
                  <div class="options-popup-inner-content">
                    <div class="options-popup-inner-content-inner">
                      <button
                        @click="activeSelectOptionPopupNumber = null"
                        class="bnts btn-close"
                        title="close"
                      ><i class="icons i24x24 i-close"></i></button>
                      <h3 class="popup-header">{{ t('label.select_option') }}</h3>

                      <div class="option-items-container">
                        <a v-for="retOption in voucher.retailOptions" class="option-item option-item--popup"
                           @click="selectOption(retOption)"
                        >
                          <div class="option-img-wrapper" v-if="retOption.image"
                               :style="{'background-image': `url('${retOption.image.src}')`}"
                          ></div>
                          <h5 v-if="retOption.name">{{ retOption.name }}</h5>
                          <p v-if="retOption.description">{{ retOption.description }}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card__bottom">
              <div class="card__price">
                <voucher-custom-select
                  v-if="voucher.priceType === 'STEP'"
                  v-bind:label="t('label.amount_selection')"
                  name="option"
                  v-model:value="option"
                  v-bind:items="getSelectOptions(voucher, data.currencySymbol)"
                ></voucher-custom-select>
                <div v-else>
                  <div class="card__label">{{ t('label.retail_value') }}</div>
                  {{ numberFormat(getProductVariantPrice(voucher, option, true), data.currencySymbol) }}
                  <div class="card__label" v-if="isRetail && data.retailTaxPercentage">
                    {{ data.pricesTaxInclusive ? t('label.tax_including') : t('label.tax_excluding') }}
                    {{ data.retailTaxDescription + ' ' + data.retailTaxPercentage }}%
                  </div>
                </div>

              </div>
              <div class="card__btns">
                <button class="btns btn-orange" v-bind:class="{
                  disabled: (!option && voucher.priceType !== 'FIXED') || (voucher.multipleOptions && !areAllSelected())
                }"
                        v-on:click.prevent="addToCart">
                  {{ t('button.add_to_cart') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <footer-line></footer-line>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { computed, ref } from 'vue'
  import HeaderLine from "@/components/HeaderLine";
  import FooterLine from "@/components/FooterLine";
  import VoucherCustomSelect from "@/components/VoucherCustomSelect";
  import { getSelectOptions } from '@/helpers/VoucherVariantsHelper'
  import { numberFormat } from '@/helpers/PriceHelper'
  import { MUTATION_TYPES } from "@/constants";
  import usePrice from "@/helpers/usePrice";
  import { isProductRetail } from "@/helpers/ProductHelper";

  export default {
    name: 'product',
    components: { VoucherCustomSelect, FooterLine, HeaderLine },
    setup() {
      const store = useStore();
      const vouchers = computed(() => store.getters.vouchers);
      const selectedOptions = computed(() => store.getters.selectedOptions.options);
      const selectedOptionsId = computed(() => store.getters.selectedOptions.productId);
      const data = computed(() => store.getters.data);
      const { getProductVariantPrice } = usePrice();

      const route = useRoute();
      const voucherId = parseInt(route.params.id);
      const voucher = vouchers.value
        ? vouchers.value.find(voucher => (voucher.id === voucherId))
        : null;
      const isRetail = computed(() => isProductRetail(voucher));
      const router = useRouter();
      if (!voucher) {
        router.push({ name: 'home', params: route.params });
        return;
      }
      if (selectedOptionsId.value && selectedOptionsId.value != voucherId) {
        store.commit(MUTATION_TYPES.setProductOption, {
          productId: voucher.id,
        });
      }

      const option = ref(null);
      const activeSelectOptionPopupNumber = ref(null);

      const addToCart = () => {
        if (
          (!option.value && voucher.priceType !== 'FIXED')
          || (voucher.multipleOptions && !areAllSelected())
        ) {
          return;
        }

        if (voucher.multipleOptions) {
          const optionCounts = {};
          for (let i = 1; i <= voucher.multipleOptions; i++) {
            const name = selectedOptions.value[i].name;
            if (!optionCounts[name]) {
              optionCounts[name] = 1;
            } else {
              optionCounts[name]++;
            }
          }
          option.value = Object.entries(optionCounts).map((arr) => `${arr[1]}x ${arr[0]}`).join(', ');
        }

        store.dispatch('addItemToCart', { 'voucher': voucher, option: option.value });
        router.push({ name: 'cart', params: route.params });
      }

      const changeCategory = (categoryId) => {
        store.commit(MUTATION_TYPES.setCurrentCategory, categoryId);
        router.push({ name: 'shop', params: route.params });
      };

      const i18n = useI18n();

      const categories = computed(() => store.getters.categories);

      const selectOption = (selectedOption) => {
        store.commit(MUTATION_TYPES.setProductOption, {
          productId: voucher.id,
          index: activeSelectOptionPopupNumber.value,
          option: selectedOption,
        });
        activeSelectOptionPopupNumber.value = null;
      };

      const areAllSelected = () => {
        for (let i = 1; i <= voucher.multipleOptions; i++) {
          if (!selectedOptions.value[i]) {
            return false;
          }
        }

        return true;
      };

      return {
        t: i18n.t,
        addToCart,
        option,
        selectedOptions,
        activeSelectOptionPopupNumber,
        selectOption, areAllSelected,
        getProductVariantPrice,
        isRetail,
        categories,
        changeCategory,
        getSelectOptions,
        voucher,
        numberFormat,
        data
      };
    }
  }
</script>
