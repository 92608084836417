<template>
  <teleport to="body">
    <div class="page__wrapper">
      <div class="content content--center">
        <section class="preloader">
          <div class="preloader__text">{{ t('message.please_wait') }}</div>
          <preloader></preloader>
        </section>
      </div>
    </div>
  </teleport>
</template>

<script>
import {useI18n} from "vue-i18n";
import Preloader from "@/components/Preloader";

export default {
  name: 'global-loader',
  components: {Preloader},
  setup() {
    return {t: useI18n().t}
  }
}
</script>

<style lang="scss">
.preloader {
  text-align: center;

  &__text {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.13;
    color: rgba(0, 0, 0, 0.87);
  }
}


</style>