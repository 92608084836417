<template>
  <header-line></header-line>
  <div class="content" v-bind:class="{'header_wo_logo': data.hideHeaderLogo}">
    <div class="site-width">
      <section class="vouchers">
        <div class="vouchers__tabs">
          <ul class="tabs__menu">
            <li class="tabs__item">
              <router-link class="tabs__link" v-bind:to="{name: 'home', params: {lang}}">{{ t('label.home') }}</router-link>
            </li>
            <li class="tabs__item ui-tabs-active">
              <a class="tabs__link" href="#" v-on:click.prevent="changeCategory('all')">{{ t('label.shop') }}</a>
            </li>
            <li class="tabs__item">
              <router-link class="tabs__link" v-bind:to="{name: 'about', params: {lang}}">{{ t('label.about') }}</router-link>
            </li>
          </ul>
          <ul class="tabs__menu" :class="{'tabs__menu--secondary': !isIframed}">
            <li class="tabs__item" v-for="category in categories" v-bind:key="category.id"
                v-bind:class="{'ui-tabs-active': category.id === currentCategory}">
              <a class="tabs__link" href="#" v-on:click.prevent="changeCategory(category.id)">{{ category.name }}</a>
            </li>
          </ul>

          <div class="tabs__content">
            <div class="tabs__item" id="all">

              <template v-if="currentCategory === 'all'">
                <div class="tabs__section" v-for="category in categories" v-bind:key="category.id + 'section'">
                  <div class="section__description" v-if="category.description" v-html="category.description"/>
                  <div class="section__title" v-else-if="categories.length > 1">{{ category.name }}</div>

                  <div class="vouchers__list card__list">

                    <div class="card card--small" v-for="voucher in getVouchersByCategory(category.id)" v-bind:key="voucher.id"
                         :class="{'card--no-desc': voucher.hideCardDescription}"
                    >
                      <figure class="card__image">
                        <router-link class="card__link--image"
                                     v-bind:to="{name: 'product', params: {id: voucher.id, lang}}"
                        >
                          <img v-bind:src="voucher.image_1x.src"
                               v-bind:alt="voucher.name"
                               v-bind:srcset="voucher.image_2x.src +' 2x, ' + voucher.image_3x.src + ' 3x'"/>
                        </router-link>
                      </figure>
                      <div class="card__block">
                        <h2 class="card__title">
                          <router-link class="card__link"
                                       v-bind:to="{name: 'product', params: {id: voucher.id, lang}}"
                          >
                            {{ voucher.name }}
                          </router-link>
                        </h2>
                        <div class="card__description" v-html="voucher.description"></div>
                        <div class="card__price">{{ prices(voucher) }}</div>
                        <div class="card__btns">
                          <button class="btns btn-cart" v-on:click.prevent="addToCart(voucher)">
                            <svg class="icon i24x24">
                              <use xlink:href="#i-cart"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </template>
              <div class="tabs__section" v-else>
                <!--                <div class="section__title">{{ category.name }}</div>-->
                <div class="section__description"
                     v-if="currentCategoryItem && currentCategoryItem.description"
                     v-html="currentCategoryItem.description"
                />
                <div class="vouchers__list card__list">
                  <div class="card card--small" v-for="product in vouchers" v-bind:key="product.id"
                       :class="{'card--no-desc': product.hideCardDescription}"
                  >
                    <product-list-item v-bind:product="product"/>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <footer-line></footer-line>
</template>

<script>
import HeaderLine from "@/components/HeaderLine";
import FooterLine from "@/components/FooterLine";
import ProductListItem from "@/components/ProductListItem";
import {useStore} from 'vuex'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";
import usePrice from "@/helpers/usePrice";
import { useI18n } from "vue-i18n";

export default {
  name: 'shop',
  components: {FooterLine, HeaderLine, ProductListItem},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();

    const vouchers = computed(() => store.getters.filteredVouchers);
    const data = computed(() => store.getters.data);
    const {getProductPriceForList} = usePrice();

    const getVouchersByCategory = (category) => {
      if (!vouchers.value) {
        return [];
      }

      return vouchers.value.filter(voucher => voucher.categories.indexOf(category) !== -1);
    };

    const router = useRouter();
    const addToCart = (product) => {
      if (product.priceType === 'FIXED' && !product.multipleOptions) {
        store.dispatch('addItemToCart', {'voucher': product, option: null});
        router.push({name: 'cart', params: route.params});
      } else {
        router.push({name: 'product', params: {...route.params, id: product.id}});
      }
    }

    const categories = computed(() => store.getters.categories);
    const currentCategory = computed(() => store.getters.currentCategory);
    const currentCategoryItem = computed(() => categories.value.find((el) => el.id === currentCategory.value));

    const changeCategory = (categoryId) => store.commit(MUTATION_TYPES.setCurrentCategory, categoryId);

    return {
      vouchers,
      t,
      prices: getProductPriceForList,
      addToCart,
      categories,
      changeCategory,
      currentCategory,
      currentCategoryItem,
      data,
      isIframed: window.location.href.indexOf('iframed') !== -1 || (window.localStorage.getItem('iframed') === '1'),
      getVouchersByCategory
    };
  }
}
</script>
