const Closable = {
	mounted(el, binding) {
		el.handleOusideClick = function (e) {
			e.stopPropagation();
			const elStyle = window.getComputedStyle(el);
			if (elStyle.display === 'none' || el.offsetParent === null) {
				return;
			}

			const {handler, exclude} = binding.value;
			let clickedOnExcludedEl = false;
			exclude.forEach(elmentClass => {
				if (!clickedOnExcludedEl) {
					if (e.target.classList.contains(elmentClass)) {
						clickedOnExcludedEl = true;
					}
					if (!clickedOnExcludedEl) {
						const excludedElements = document.getElementsByClassName(elmentClass);
						if (excludedElements && excludedElements.length) {
							excludedElements.forEach(function (excludedElement) {
								if (excludedElement.contains(e.target)) {
									clickedOnExcludedEl = true;
								}
							})
						}
					}
				}
			});

			if (!el.contains(e.target) && !clickedOnExcludedEl) {
				binding.instance[handler]();
			}
		};
		document.addEventListener('click', el.handleOusideClick);
		document.addEventListener('touchstart', el.handleOusideClick);
	},
	beforeUnmount(el) {
		document.removeEventListener('click', el.handleOusideClick);
		document.removeEventListener('touchstart', el.handleOusideClick);
	}
};

export default Closable;