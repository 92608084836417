import { createWebHistory, createRouter } from 'vue-router';
import Home from "@/pages/Home";
import Shop from "@/pages/Shop";
import BaseTenant from "@/pages/BaseTenant";

const Cart = import("@/pages/Cart");
const Product = import("@/pages/Product");
const Checkout = import("@/pages/Checkout");
const Payment = import("@/pages/Payment");
const Confirmation = import("@/pages/Confirmation");
const Terms = import("@/pages/Terms");
const About = import("@/pages/About");
const Contact = import("@/pages/Contact");
const Search = import("@/pages/Admin/Search");
const Login = import("@/pages/Admin/Login");
const AdminVoucher = import("@/pages/Admin/Voucher");
const AdminRedeem = import("@/pages/Admin/Redeem");
const NotFound = import("@/pages/NotFound");
const NotFoundTenant = import("@/pages/NotFoundTenant");

const blackListTenant = [
    'shop', 'product', 'cart', 'checkout', 'payment', 'confirmation', 'terms', 'about', 'contact', 'admin', 'en', 'de'
];
const tenant = '/:tenantId([A-z\-0-9]{3,})?/:lang(en|de)?';
const routes = [
    {
        path: tenant,
        component: BaseTenant,
        beforeEnter: (to, from, next) => {
            blackListTenant.includes(to.params.tenantId)
                ? next('/')
                : next();
        },
        children: [
            {
                path: tenant + '/',
                component: Home,
                name: 'home',
                meta: {
                    title: 'Home',
                    adminPage: false
                }
            },
            {
                path: tenant + '/shop',
                component: Shop,
                name: 'shop',
                meta: {
                    title: 'Shop',
                    adminPage: false
                }
            },
            {
                path: tenant + '/product/:id',
                component: () => Product,
                name: 'product',
                meta: {
                    title: 'Product',
                    adminPage: false
                }
            },
            {
                path: tenant + '/cart',
                component: () => Cart,
                name: 'cart',
                meta: {
                    title: 'Cart',
                    adminPage: false
                }
            },
            {
                path: tenant + '/checkout',
                component: () => Checkout,
                name: 'checkout',
                meta: {
                    title: 'Checkout',
                    adminPage: false
                }
            },
            {
                path: tenant + '/payment',
                component: () => Payment,
                name: 'payment',
                meta: {
                    title: 'Payment',
                    adminPage: false
                }
            },
            {
                path: tenant + '/confirmation',
                component: () => Confirmation,
                name: 'confirmation',
                meta: {
                    title: 'Confirmation',
                    adminPage: false
                }
            },
            {
                path: tenant + '/terms',
                component: () => Terms,
                name: 'terms',
                meta: {
                    title: 'Terms and Conditions',
                    adminPage: false
                }
            },
            {
                path: tenant + '/about',
                component: () => About,
                name: 'about',
                meta: {
                    title: 'About Us',
                    adminPage: false
                }
            },
            {
                path: tenant + '/contact',
                component: () => Contact,
                name: 'contact',
                meta: {
                    title: 'Contact Us',
                    adminPage: false
                }
            },
            {
                path: tenant + '/admin',
                component: () => Search,
                name: 'admin_search',
                meta: {
                    title: 'Admin',
                    adminPage: true
                },
            },
            {
                path: tenant + '/admin/login',
                component: () => Login,
                name: 'admin_login',
                meta: {
                    title: 'Sign In',
                    adminPage: true
                },
            },
            {
                path: tenant + '/admin/:code/voucher',
                component: () => AdminVoucher,
                name: 'admin_voucher',
                meta: {
                    title: 'Voucher',
                    adminPage: true
                },
            },
            {
                path: tenant + '/admin/:code/redeem',
                component: () => AdminRedeem,
                name: 'admin_redeem',
                meta: {
                    title: 'Redeem',
                    adminPage: true
                },
            },
            {
                path: tenant + '/:pathMatch(.*)*',
                component: () => NotFound,
                name: 'not_found',
                meta: {
                    title: 'Not Found',
                    adminPage: false
                },
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => NotFoundTenant,
        name: 'not_found_tenant',
        meta: {
            title: 'Not Found',
            adminPage: false
        },
    },
];

export default createRouter({
    routes,
    history: createWebHistory()
})
