<template>
  <figure class="card__image">
    <router-link class="card__link--image"
                 v-bind:to="{name: 'product', params: {id: product.id, lang}}"
    >
      <img v-if="product.image_1x"
           v-bind:src="product.image_1x.src"
           v-bind:alt="product.name"
           v-bind:srcset="product.image_2x.src +' 2x, ' + product.image_3x.src + ' 3x'" />
    </router-link>
  </figure>
  <div class="card__block">
    <h2 class="card__title">
      <router-link class="card__link"
                   v-bind:to="{name: 'product', params: {id: product.id, lang}}"
      >
        {{ product.name }}
      </router-link>
    </h2>
    <div class="card__description" v-html="product.description" role="link"
         style="cursor: pointer"
         @click="$router.push({name: 'product', params: {id: product.id}});"></div>
    <div class="card__price" @click="$router.push({name: 'product', params: {id: product.id}});"
    >{{ prices(product) }}
    </div>
    <div class="card__btns">
      <button class="btns" v-on:click.prevent="addToCart(product)" v-if="!hideAddToCard">
        <svg class="icon i24x24">
          <use xlink:href="#i-cart"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
  import usePrice from "@/helpers/usePrice";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { ACTION_TYPES } from "@/constants";

  export default {
    name: 'product-list-item',
    setup(props) {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const addToCart = (product) => {
        if (product.priceType === 'FIXED' && !product.multipleOptions) {
          store.dispatch(ACTION_TYPES.addItemToCart, { 'voucher': product, option: null });
          router.push({ name: 'cart', params: route.params });
        } else {
          router.push({ name: 'product', params: { ...route.params, id: product.id } });
        }
      }
      const { getProductPriceForList } = usePrice();

      return { product: props.product, prices: getProductPriceForList, addToCart }
    },
    props: {
      hideAddToCard: {
        type: Boolean,
      },
      product: {
        type: [Object],
      }
    }
  };
</script>
