import { ACTION_TYPES } from "@/constants";
import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router';
import i18n from "@/i18n";
import Closable from "@/directives";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueRecaptcha from 'vue3-recaptcha-v2';

require('./scss/main.scss');

const urlParts = window.location.pathname.split('/');
let urlLang = urlParts[2];
if (!urlLang || urlLang.length !== 2) {
    if (urlParts[1] && urlParts[1].length === 2) {
        urlLang = urlParts[1];
    } else {
        urlLang = 'en';
    }
}

const app = createApp(App);

app.config.globalProperties.lang = urlLang;

app.use(store);
app.use(router);
app.use(i18n);

app.directive('closable', Closable);

router.beforeEach((to, from, next) => {
    const routeRecordNormalizeds = to.matched.slice().reverse();
    const nearestWithTitle = routeRecordNormalizeds.find(r => r.meta && r.meta.title);
    if (nearestWithTitle) {
        const data = store.getters.data;
        document.title = nearestWithTitle.meta.title + (data ? ' - ' + data.name : '');
    }

    const nearestWithAdmin = routeRecordNormalizeds.find(r => r.meta && r.meta.adminPage);
    if (nearestWithAdmin) {
        if (!store.getters.admin && !store.getters.isAdminLoaded && !store.getters.isAdminLoading) {
            store.dispatch(ACTION_TYPES.loadAdmin);
        }
    }

    if (!to.params.hasOwnProperty('lang')) {
        const params = {
            ...to.params,
            lang: window.lang,
        };
        if (from.params.tenantId || to.params.tenantId) {
            params.tenantId = from.params.tenantId || to.params.tenantId;
        }
        next({ ...to, params });
    } else if (from.params.tenantId && !to.params.tenantId) {
        next({ ...to, params: { ...to.params, tenantId: from.params.tenantId } });
    } else {
        next();
    }
});

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDlA6QgelCIboITQ0Fp87QkjAWQ4ivGSsk',
    },
});
app.use(VueRecaptcha, {
    siteKey: '6Le7H3cfAAAAALiU2Tv0VeuP0mHO0OkcEatE0tan',
    alterDomain: true,
});

app.mount('#app');
