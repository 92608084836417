export const MUTATION_TYPES = {
    decreaseItemCount: 'decreaseItemCount',
    pushItemToCart: 'pushItemToCart',
    removeItemFromCart: 'removeItemFromCart',
    setAdmin: 'setAdmin',
    setAdminLoading: 'setAdminLoading',
    setAdminLoaded: 'setAdminLoaded',
    setCartItems: 'setCartItems',
    setCsrfToken: 'setCsrfToken',
    setCurrentCategory: 'setCurrentCategory',
    setData: 'setData',
    setDataLoading: 'setDataLoading',
    setTerms: 'setTerms',
    setTermsLoading: 'setTermsLoading',
    setAbout: 'setAbout',
    setAboutLoading: 'setAboutLoading',
    setContact: 'setContact',
    setContactLoading: 'setContactLoading',
    setReservation: 'setReservation',
    setReservationLoading: 'setReservationLoading',
    setReservationLoaded: 'setReservationLoaded',
    setSessionId: 'setSessionId',
    setSignInError: 'setSignInError',
    setBookedVoucher: 'setBookedVoucher',
    setTenantId: 'setTenantId',
    setReservationFetchParams: 'setReservationFetchParams',
    setUserReference: 'setUserReference',
    syncStorage: 'syncStorage',
    setDomainTenantId: 'setDomainTenantId',
    setDomainTenantLoaded: 'setDomainTenantLoaded',
    setProductOption: 'setProductOption',
};

export const ACTION_TYPES = {
    addItemToCart: 'addItemToCart',
    loadMainData: 'loadMainData',
    loadTerms: 'loadTerms',
    loadAbout: 'loadAbout',
    loadContact: 'loadContact',
    loadAdmin: 'loadAdmin',
    loadReservation: 'loadReservation',
    signIn: 'signIn',
    logout: 'logout',
    updateTenant: 'updateTenant',
    storeReservationFetchParams: 'storeReservationFetchParams',
    syncWithStorage: 'syncWithStorage',
    clearCart: 'clearCart',
    findBookedVoucher: 'findBookedVoucher',
    cleanBookedVoucher: 'cleanBookedVoucher',
    loadDomainTenant: 'loadDomainTenant',
};


export const KEYS = {
    cartStorageKey: 'citems',
    reservationFetchParamsStorageKey: 'rfparams',
    lastTenant: 'tenId',
};


export const ERROR_TYPES = {
    exceeded_max_amount: 'error.exceeded_max_amount',
    required: 'error.required',
    too_short: 'error.too_short',
    too_long: 'error.too_long',
    too_match_new_lines: 'error.too_match_new_lines',
    invalid_email: 'error.invalid_email',
};
