import {createI18n} from 'vue-i18n'

function loadLocaleMessages(langCode) {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\/translation.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		if (!key) {
			return;
		}

		const matched = key.match(/([A-Za-z0-9-_]+)/i)
		if (matched && matched.length > 1) {
			const locale = matched[1];
			if (locale !== 'en' && locale !== langCode) {
				return;
			}
			messages[locale] = locales(key)
		}
	})
	window.msg = messages;
	window.locales = locales;
	window.lang = langCode;

	return messages
}

const urlParts = window.location.pathname.split('/');
let urlLang = urlParts[2];
if (!urlLang || urlLang.length !== 2) {
	if (urlParts[1] && urlParts[1].length === 2) {
		urlLang = urlParts[1];
	} else {
		urlLang = 'en';
	}
}

export default createI18n({
	locale: urlLang,
	fallbackLocale: 'en',
	messages: loadLocaleMessages(urlLang)
})