export default new class LocalStorageHandler {
	constructor() {
		this.localStorage = window.localStorage;
	}

	setCookieAccepted(isAccepted) {
		this.set('cp', isAccepted ? 'Y' : 'N');
	}


	remove(key) {
		this.localStorage.removeItem(key);
	}

	set(key, value) {
		return this.localStorage.setItem(key, LocalStorageHandler._transform(value));
	}

	get(key) {
		return LocalStorageHandler._reverseTransform(this.localStorage.getItem(key));
	}

	static _transform(data) {
		return JSON.stringify(data);
	}

	static _reverseTransform(data) {
		return JSON.parse(data);
	}
}