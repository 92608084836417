const getSelectOptions = (voucher, currencySymbol) => {
	let options = {};
	switch (voucher.priceType) {
		case 'FIXED':
			options = [];
			break;
		case 'VARIANT':
			voucher.variants.forEach((el) => {
				options[el.name] = el.name;
			});
			break;
		case 'STEP':
			for (let i = voucher.priceMinimum; i <= voucher.priceMaximum; i += voucher.priceStep) {
				options[i] = currencySymbol + i;
			}
			break;
	}
	return options;
};

export {getSelectOptions};