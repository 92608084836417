<template>
  <div class="page__wrapper" v-if="!loading">
    <router-view></router-view>
  </div>
  <global-loader v-else></global-loader>

</template>

<script>
  import { useRouter, useRoute } from "vue-router";
  import { ACTION_TYPES, KEYS } from "@/constants";
  import { useStore } from "vuex";
  import { computed, watchEffect, ref } from 'vue'
  import GlobalLoader from "@/components/GlobalLoader";
  import LocalStorageHandler from "@/api/LocalStorageHandler";

  export default {
    name: 'base_tenant',
    components: { GlobalLoader },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const tenantId = route.params.tenantId;
      const lang = route.params.lang;
      const store = useStore();
      const data = computed(() => store.getters.data);
      const domainTenantId = computed(() => store.getters.domainTenantId);
      const domainTenantLoaded = computed(() => store.getters.domainTenantLoaded);
      const loading = ref(true);

      const theme = () => import('../scss/theme_ki.scss');
      watchEffect(() => {
        if (!domainTenantId.value && !tenantId) {
          return;
        }
        if (!tenantId && domainTenantLoaded.value && !domainTenantId.value) {
          router.push({ name: 'not_found_tenant' })
          return;
        }
        if (!data.value && !store.getters.isDataLoading) {
          store.dispatch(ACTION_TYPES.loadMainData);
        }
        if (data.value && data.value.theme === 'ki') {
          theme();
        }
        loading.value = !data.value;
        document.title = document.title + (data.value ? ' - ' + data.value.name : '');

        const currentTenantId = tenantId || domainTenantId.value;
        const lastVisitedTenant = LocalStorageHandler.get(KEYS.lastTenant);
        if (lastVisitedTenant !== currentTenantId && currentTenantId) {
          LocalStorageHandler.set(KEYS.lastTenant, currentTenantId);
          if (lastVisitedTenant) {
            LocalStorageHandler.remove(KEYS.cartStorageKey);
            LocalStorageHandler.remove(KEYS.reservationFetchParamsStorageKey);
          }
        }
      });

      if (!tenantId && !domainTenantLoaded.value && !domainTenantId.value) {
        store.dispatch(ACTION_TYPES.loadDomainTenant);
        return { loading };
      }

      if (tenantId && !domainTenantId.value) {
        store.dispatch(ACTION_TYPES.updateTenant, tenantId);
      }

      if (!lang) {
        router.push({ name: route.name, params: { ...route.params, lang: 'en' } });
      }

      return { loading }
    }
  }
</script>

<style lang="scss">
  @import "../scss/mixins";

  .page__wrapper {
    @include flex_justify_align(column, space-between, stretch);
    width: 100%;
    min-height: 100vh;
  }
</style>