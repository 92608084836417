import {isRef} from 'vue'

const getCartItemPrice = (item) => {
	const voucher = item.data ? item.data.voucher : item.voucher;
	const option = item.data ? item.data.option : item.option;
	let price = getProductVariantPrice(voucher, option);

	return price * 1000 * (item.count || 1) / 1000;
};

const getProductVariantPrice = (voucher, option) => {
	let price;
	switch (voucher.priceType) {
		case 'FIXED':
			price = voucher.fixedPrice;
			break;
		case 'VARIANT':
			voucher.variants.forEach((el) => {
				if (option == el.name) { // should check string and int
					price = el.price;
				}
			});
			break;
		case 'STEP':
			price = option;
			break;
	}
	return price;
};

const getCartTotalPrice = (cartItems) => {
	let totalPrice = 0;
	const items = isRef(cartItems) ? cartItems.value : cartItems;
	if (!items) {
		return 0;
	}
	items.forEach((el) => {
		totalPrice += getCartItemPrice(el) * 1000;
	})
	return totalPrice / 1000;
};

const numberFormat = (amount, currencySymbol, inCents) => {
	try {
		amount = parseFloat(amount);
		if (inCents) {
			amount = amount / 100;
		}
		const decimalCount = 2;
		const thousands = ",";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return currencySymbol
			+ (j ? i.substr(0, j) + thousands : '')
			+ i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands)
			+ "." + Math.abs(amount - i).toFixed(decimalCount).slice(2);
	} catch (e) {
		console.log(e)
	}
};

export {getCartItemPrice, getCartTotalPrice, getProductVariantPrice, numberFormat};