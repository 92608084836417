import { computed, isRef } from 'vue'
import {useStore} from 'vuex'
import { numberFormat } from "@/helpers/PriceHelper";
import {isProductRetail} from "@/helpers/ProductHelper";

export default function () {
	const store = useStore();
	const data = computed(() => store.getters.data);

	const getProductVariantPrice = (product, option, ignoreTaxes = false) => {
		let price;
		switch (product.priceType) {
			case 'FIXED':
				price = +product.fixedPrice;
				break;
			case 'VARIANT':
				product.variants.forEach((el) => {
					if (option == el.name) { // should check string and int
						price = +el.price;
					}
				});
				break;
			case 'STEP':
				price = +option;
				break;
		}

		if (!ignoreTaxes && data.value.pricesTaxInclusive && isProductRetail(product)) {
			const taxMultiplier = data.value.retailTaxPercentage / 100;
			price = price / (1 + taxMultiplier);
		}

		return Math.round(price * 100) / 100;
	};

	const getCartItemPrice = (item, ignoreTaxes = false) => {
		const product = item.data ? item.data.voucher : item.voucher;
		const option = item.data ? item.data.option : item.option;
		let price = getProductVariantPrice(product, option, ignoreTaxes); // tax handled

		return price * 1000 * (item.count || 1) / 1000;
	};

	const getCartTotalPrice = (cartItems, paymentInfo) => {
		let totalPrice = 0;
		const items = isRef(cartItems) ? cartItems.value : cartItems;
		if (!items) {
			return 0;
		}
		items.forEach((el) => {
			const itemPrice = getCartItemPrice(el, true) * 1000;
			totalPrice += itemPrice;
			if (!data.value.pricesTaxInclusive && isProductRetail(el.data ? el.data.voucher : el.voucher)) {
				const taxMultiplier = data.value.retailTaxPercentage / 100;
				totalPrice += itemPrice * taxMultiplier;
			}
		});
		totalPrice = totalPrice / 1000;
		totalPrice += getCartGiftTotal(items);
		totalPrice += getCartShippingTotal(items, paymentInfo);

		return Math.round(totalPrice * 100) / 100;
	};

	const getCartGiftTotal = (cartItems) => {
		let totalPrice = 0;
		const items = isRef(cartItems) ? cartItems.value : cartItems;
		if (!items) {
			return 0;
		}
		items.forEach((el) => {
			const product = el.data ? el.data.voucher : el.voucher;
			const checkoutInfo = el.data ? el.data.checkoutInfo : el.checkoutInfo;
			if (product && product.giftSurcharge && checkoutInfo && checkoutInfo.isGift) {
				totalPrice += product.giftSurcharge * 1000;
			}
		});
		totalPrice = totalPrice / 1000;

		return Math.round(totalPrice * 100) / 100;
	};

	const getCartShippingTotal = (cartItems, paymentInfo) => {
		const items = isRef(cartItems) ? cartItems.value : cartItems;
		if (!items || !paymentInfo) {
			return 0;
		}
		const shippingCountryName = (paymentInfo.deliverRetailTo !== 'ME' && paymentInfo.countryShipping)
			|| (paymentInfo.deliverRetailTo === 'ME' && paymentInfo.country);
		if (!shippingCountryName) {
			return 0;
		}
		const country = data.value.shippingCountries
			&& data.value.shippingCountries.find((el) => el.name === shippingCountryName);
		if (!country || !country.price) {
			return 0;
		}
		const count = items.filter((el) => isProductRetail(el.data ? el.data.voucher : el.voucher)).length;
		if (!count) {
			return 0;
		}
		const totalPrice = country.price * Math.ceil(count / (country.max_items || 1));

		return Math.round(totalPrice * 100) / 100;
	};

	const getCartTotalTax = (cartItems) => {
		let totalTax = 0;
		const items = isRef(cartItems) ? cartItems.value : cartItems;
		if (!items) {
			return 0;
		}
		items.forEach((el) => {
			const product = el.data ? el.data.voucher : el.voucher;
			if (isProductRetail(product)) {
				const itemPrice = getCartItemPrice(el, true);
				const taxMultiplier = data.value.retailTaxPercentage / 100;

				if (data.value.pricesTaxInclusive) {
					totalTax += itemPrice - (itemPrice / (1 + taxMultiplier));
				} else {
					totalTax += itemPrice * taxMultiplier;
				}
			}
		});

		return totalTax;
	};

	const getCartRetailTotalTax = (cartItems) => {
		return getCartTotalTax(cartItems.filter((el) => isProductRetail(el.voucher || el.data.voucher)));
	};

	const getProductPriceForList = (product) => {
		let minPrice = 0;
		let maxPrice = 0;
		let result;

		switch (product.priceType) {
			case 'FIXED':
				return numberFormat(product.fixedPrice, data.value.currencySymbol);
			case 'VARIANT':
				product.variants.forEach((el) => {
					if (!minPrice || el.price > maxPrice) {
						maxPrice = el.price;
					}
					if (!minPrice || el.price < minPrice) {
						minPrice = el.price;
					}
				})

				result = numberFormat(minPrice, data.value.currencySymbol);
				if (product.variants.length > 1) {
					result += ' - ' + numberFormat(maxPrice, data.value.currencySymbol);
				}

				return result;
			case 'STEP':
				return numberFormat(product.priceMinimum, data.value.currencySymbol)
					+ ' - '
					+ numberFormat(product.priceMaximum, data.value.currencySymbol);
		}
	}

	return {
		getProductPriceForList, getCartItemPrice, getCartTotalPrice, getProductVariantPrice, getCartTotalTax,
		getCartRetailTotalTax, getCartGiftTotal, getCartShippingTotal
	}
}