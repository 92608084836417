import RequestHandler from '@apiClient/RequestHandler';
import LocalStorageHandler from "@/api/LocalStorageHandler";
import {ACTION_TYPES, MUTATION_TYPES} from "@/constants";

const sessionId = LocalStorageHandler.get('sesId');
const csrfToken = LocalStorageHandler.get('sesToken');

const state = () => ({
	sessionId: sessionId,
	csrfToken: csrfToken,
	userReference: null,
	admin: null,
	adminLoading: false,
	adminLoaded: false,
	bookedVoucher: null,
	bookedVoucherLoading: false,
	signInError: false
});

const adminWakeUp = (context) => {
	RequestHandler.sessionRefresh(context.state.sessionId, context.state.csrfToken, (data) => {
		if (data.status && data.status !== 200) {
			context.commit(MUTATION_TYPES.setAdmin, null);
			context.commit(MUTATION_TYPES.setAdminLoading, false);
			context.commit(MUTATION_TYPES.setAdminLoaded, true);
		} else {
			parceSession(data, context);
		}
	});
}

const fetchUser = (context) => {
	RequestHandler.fetchResource(context.state.userReference, (data) => {
		if (data.status && data.status !== 200) {
			context.commit(MUTATION_TYPES.setAdmin, null);
		} else {
			context.commit(MUTATION_TYPES.setAdmin, data.User);
		}
		context.commit(MUTATION_TYPES.setAdminLoading, false);
		context.commit(MUTATION_TYPES.setAdminLoaded, true);
	});
}

const parceSession = (data, context) => {
	const session = data.Session;
	context.commit(MUTATION_TYPES.setUserReference, session.User);
	context.commit(MUTATION_TYPES.setSessionId, session.identifier);
	context.commit(MUTATION_TYPES.setCsrfToken, session.csrfToken);
	context.commit(MUTATION_TYPES.setSignInError, false);
	fetchUser(context);
}

const actions = {
	[ACTION_TYPES.loadAdmin]: (context) => {
		context.commit(MUTATION_TYPES.setAdminLoading, true);
		context.commit(MUTATION_TYPES.setAdminLoaded, false);
		// try to load from local storage
		let wakingUp = false;
		if (!context.state.userReference && context.state.sessionId && context.state.csrfToken) {
			wakingUp = true;
			adminWakeUp(context);
		}
		// try to load from state storage
		let fetching = false;
		if (context.state.userReference) {
			fetching = true;
			fetchUser(context);
		}
		// not stored anywhere loaded null
		if (!fetching && !wakingUp) {
			// workaround to catch changes
			setTimeout(() => {
				context.commit(MUTATION_TYPES.setAdminLoading, false);
				context.commit(MUTATION_TYPES.setAdminLoaded, true);
			}, 10);
		}
	},

	[ACTION_TYPES.signIn]: (context, payload) => {
		context.commit(MUTATION_TYPES.setAdminLoading, true);
		context.commit(MUTATION_TYPES.setAdminLoaded, false);
		context.commit(MUTATION_TYPES.setSignInError, false);
		RequestHandler.signIn(payload, (data) => {
			if (data.status && data.status > 201) {
				context.commit(MUTATION_TYPES.setAdmin, null);
				context.commit(MUTATION_TYPES.setSignInError, true);
				context.commit(MUTATION_TYPES.setAdminLoading, false);
				context.commit(MUTATION_TYPES.setAdminLoaded, true);
			} else {
				parceSession(data, context);
			}
		});
	},
	[ACTION_TYPES.logout]: (context) => {
		context.commit(MUTATION_TYPES.setAdminLoading, true);
		context.commit(MUTATION_TYPES.setAdminLoaded, false);
		RequestHandler.logout(context.state.sessionId, context.state.csrfToken, () => {
			context.commit(MUTATION_TYPES.setAdmin, null);
			context.commit(MUTATION_TYPES.setAdminLoading, false);
			context.commit(MUTATION_TYPES.setAdminLoaded, true);
			context.commit(MUTATION_TYPES.setSessionId, null);
			context.commit(MUTATION_TYPES.setCsrfToken, null);
		});
	},
	[ACTION_TYPES.findBookedVoucher]: async (context, payload) => {
		try {
			const response = await RequestHandler.findBookedVoucher(payload);
			context.commit(MUTATION_TYPES.setBookedVoucher, response.data);
		} catch (err) {
			context.commit(MUTATION_TYPES.setBookedVoucher, null);
		}
	},
	[ACTION_TYPES.cleanBookedVoucher]: async (context) => {
		context.commit(MUTATION_TYPES.setBookedVoucher, null);
	},
};

const mutations = {
	[MUTATION_TYPES.setSessionId]: (state, data) => {
		state.sessionId = data;
		LocalStorageHandler.set('sesId', data);
	},
	[MUTATION_TYPES.setCsrfToken]: (state, data) => {
		state.csrfToken = data;
		LocalStorageHandler.set('sesToken', data);
	},
	[MUTATION_TYPES.setUserReference]: (state, data) => state.userReference = data,
	[MUTATION_TYPES.setAdmin]: (state, data) => state.admin = data,
	[MUTATION_TYPES.setAdminLoading]: (state, data) => state.adminLoading = data,
	[MUTATION_TYPES.setAdminLoaded]: (state, data) => state.adminLoaded = data,
	[MUTATION_TYPES.setSignInError]: (state, data) => state.signInError = data,
	[MUTATION_TYPES.setBookedVoucher]: (state, data) => state.bookedVoucher = data,
};

const getters = {
	isAdminLoading: (state) => state.adminLoading,
	isAdminLoaded: (state) => state.adminLoaded,
	isSignInError: (state) => state.signInError,
	admin: (state) => state.admin,
	bookedVoucher: (state) => state.bookedVoucher,
};

export default {state, actions, mutations, getters};
