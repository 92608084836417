<template>
  <footer class="footer" v-if="!loading">
    <div class="site-width">
      <div class="footer__logo" v-if="logo">
        <a :href="logoUrl" :title="logo.name" v-if="logoUrl" target="_blank">
          <img class="logo" v-bind:src="logo.src" v-bind:alt="logo.name">
        </a>
        <img class="logo" v-bind:src="logo.src" v-bind:alt="logo.name" v-else>
      </div>
      <div class="footer__payments">
        <div class="payments__list">
          <figure class="payments__item" v-for="partner in data.footerPartners" v-bind:key="partner.id">
            <a class="payments__link images__link"
               v-if="partner.image"
               v-bind:href="partner.link && partner.link.href"
               v-bind:title="partner.link && partner.link.name"
               v-bind:target="partner.link && partner.newTab ? '_blank' : undefined"
            >
              <img v-bind:src="partner.image.src" v-bind:alt="partner.image.alt">
            </a>
          </figure>
        </div>
      </div>
      <div class="footer__securities">
        <div class="securities__list"></div>
      </div>
      <div class="footer__menu">
        <ul class="menu__list">
          <li class="menu__item" v-for="link in data.footerLinks" v-bind:key="link.id">
            <a class="menu__link" v-bind:href="link.link.href" v-bind:title="link.link.name"
               v-bind:target="link.newTab ? '_blank' : (link.link.href.indexOf('/') !== 0 ? '_self' : '_top')"
               v-if="link.link"
            >{{ link.link.name }}</a>
          </li>
        </ul>
        <ul class="menu__list" v-if="data.languages && data.languages.length > 1">
          <li class="menu__item"
              v-for="language in data.languages"
              :key="language && language.code"
          >
            <a class="menu__link" :href="`/${data.identifier}/${language.code}/${routePath}`"
               v-if="language.code !== activeLanguageCode">
              {{ language.name }}
            </a>
            <span class="menu__link menu__link--active" v-if="language.code === activeLanguageCode">
              {{ language.name }}
            </span>
          </li>
        </ul>
      </div>
      <div class="footer__poweredby">
        <div class="poweredby__text">{{ t('label.powered_by') }}:</div>
        <figure class="poweredby__image">
          <a class="poweredby__link images__link" href="https://www.conciliolabs.com/" target="_blank" title="ConcilioLabs">
            <img
              alt="ConcilioLabs"
              v-bind:srcset="poweredBy['1x'] + ' 1x,' + poweredBy['2x'] + ' 2x, ' + poweredBy['3x'] + ' 3x'"
              v-bind:src="poweredBy['1x']"
            >
          </a>
        </figure>
      </div>

      <div class="footer__copyrights" v-html="data.footerCopyright" v-if="data.footerCopyright"></div>
    </div>
  </footer>
</template>

<script>
  import { useStore } from "vuex";
  import { computed, watchEffect, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import VoucherCustomSelect from "@/components/VoucherCustomSelect";
  import { useRoute } from "vue-router";

  export default {
    name: 'footer-line',
    components: { VoucherCustomSelect },
    setup() {
      const store = useStore();
      const route = useRoute();
      const data = computed(() => store.getters.data);
      const logo = computed(() => data.value ? (data.value.footerLogoSvg || data.value.footerLogoImage) : null);
      const logoUrl = computed(() => data.value && data.value.footerLogoUrl || undefined);
      const routePath = computed(() => {
        const path = route.path;
        const pathParts = path.split('/');
        if (route.name === 'product') {
          return pathParts[pathParts.length - 2] + '/' + pathParts[pathParts.length - 1];
        }
        return pathParts[pathParts.length - 1];
      });
      const loading = ref(false);
      const poweredBy = {
        '1x': require('../img/poweredby.png'),
        '2x': require('../img/poweredby@2x.png'),
        '3x': require('../img/poweredby@3x.png')
      }
      watchEffect(() => loading.value = !data.value)

      const i18n = useI18n();

      return { data, t: i18n.t, loading, logo, logoUrl, poweredBy, activeLanguageCode: lang, routePath };
    }
  };
</script>

<style lang="scss">
  @import "../scss/mixins";

  .footer {
    padding: 24px 12px 8px 12px;
    background-color: #fff;

    &__logo {
      display: none;
      height: 44px;

      img {
        width: auto;
        height: 100%;
      }
    }

    &__payments {
      padding: 0px 18px;

      .payments {
        &__list {
          padding-bottom: 12px;
          text-align: center;
        }

        &__item {
          display: inline-block;
          margin: 0px 4px 12px 4px;
          vertical-align: middle;
        }

        &__link {
          @include flex_justify_align(column, center, center);
          height: 31px;

          img {
            max-height: 100%;
            width: auto;
          }

        }
      }
    }

    &__securities {
      padding-bottom: 12px;
      width: 158px;

      .securities {
        &__list {
          @include flex_justify_align(row, center, center);
        }

        &__item {
          padding: 0px 12px;
        }

        &__link {
          @include flex_justify_align(column, center, center);
          height: 31px;

          img {
            max-height: 100%;
            width: auto;
          }
        }
      }
    }

    &__menu {
      margin-bottom: 24px;
      text-align: center;

      .menu {

        &__list {
          list-style: none;
          text-align: center;
        }

        &__item {
          display: inline-block;
          margin: 0 12px 12px 12px;
        }

        &__link {
          font-size: 10px;
          color: rgba(0, 0, 0, 0.6);
          text-transform: uppercase;
        }
      }
    }

    &__poweredby {
      margin-bottom: 24px;
      text-align: center;

      .poweredby {
        &__text {
          margin-bottom: 4px;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.38);
        }

        &__image {

        }
      }
    }

    &__copyrights {
      padding-top: 12px;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.38);
      text-align: center;
    }

    &--small {
      padding: 24px 12px 8px 12px;
      background-color: transparent;
      text-align: center;
    }
  }

  @media only screen and (min-width: 993px) {
    .footer {
      .site-width {
        @include flex_justify_align(row, space-between, center);
        flex-wrap: wrap
      }

      &__logo {
        display: block;
      }

      &__menu,
      &__copyrights {
        flex: 0 0 100%;
      }
    }
  }

  .menu__link--active {
    font-weight: 600;
  }
</style>