const axios = require('axios');
export default new class RequestHandler {
	constructor() {
		this.axiosInstance = axios.create({
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		});
	}

	fetchData(tenantId, callback) {
		this._doGetRequest('/data', tenantId, callback);
	}

    fetchDomainTenant(domain) {
        const config = {};
        if (window.lang) {
            config.headers = { 'x-siteaccess': window.lang };
        }

        return this.axiosInstance.get('/api/tenantByDomain/' + domain, config)
    }

	fetchReservation(params, callback) {
		this.axiosInstance.post('/api/booking/fetch', params)
			.then((response) => {
				const responseData = response.data;
				if (!responseData && responseData !== 0) {
					callback(responseData);
					return;
				}
				callback(responseData);
			})
			.catch((error) => {
				callback(null);
				console.log(error);
			});
	}

	fetchPaymentMethods(tenantId) {
		return this.axiosInstance.get('/api/adyen/paymentMethods?storeId=' + tenantId)
	}

	fetchTerms(tenantId, callback) {
		this._doGetRequest('/terms', tenantId, callback);
	}

	fetchAbout(tenantId, callback) {
		this._doGetRequest('/about', tenantId, callback);
	}

	fetchContact(tenantId, callback) {
		this._doGetRequest('/contact', tenantId, callback);
	}

	makePaymentRequest(url, data) {
		const config = {};
		if (window.lang) {
			config.headers = { 'x-siteaccess': window.lang };
		}
		return this.axiosInstance.post(url, data, config);
	}

	findBookedVoucher(code) {
		return this.axiosInstance.post('/api/redemption/find_voucher', {code});
	}

	redeemVoucher(params) {
		return this.axiosInstance.post('/api/redemption/consume_voucher', {
			pin: params.pin,
			amount: params.amount,
			comment: params.comment,
			code: params.code,
		});
	}

	sessionRefresh(sessionId, csrfToken, callback) {
		const axInstance = axios.create({
			responseType: 'json',
			headers: {
				'X-CSRF-Token': csrfToken,
				'Accept': 'application/vnd.ez.api.Session+json'
			}
		})
		axInstance.post('/api/ezp/v2/user/sessions/' + sessionId + '/refresh')
			.then((response) => {
				const responseData = response.data;
				if (!responseData && responseData !== 0) {
					alert('Unable to load data');
					return;
				}
				callback(responseData);
			})
			.catch(error => callback(error.response));
	}

	fetchResource(userReference, callback) {
		const axios = require('axios');
		const axInstance = axios.create({
			responseType: 'json',
			headers: {
				'Accept': userReference['_media-type']
			}
		})
		axInstance.get(userReference['_href'])
			.then((response) => {
				const responseData = response.data;
				if (!responseData && responseData !== 0) {
					alert('Unable to load data');
					return;
				}
				callback(responseData);
			})
			.catch(error => callback(error.response));
	}

	signIn(payload, callback) {
		const axInstance = axios.create({
			responseType: 'json',
			headers: {
				'Content-Type': 'application/vnd.ez.api.SessionInput+json',
				'Accept': 'application/vnd.ez.api.Session+json'
			}
		})
		axInstance.post('/api/ezp/v2/user/sessions', {'SessionInput': payload})
			.then((response) => {
				const responseData = response.data;
				if (!responseData && responseData !== 0) {
					alert('Unable to load data');
					return;
				}
				callback(responseData);
			})
			.catch(error => callback(error.response));
	}

	logout(sessionId, csrfToken, callback) {
		const axInstance = axios.create({
			responseType: 'json',
			headers: {'X-CSRF-Token': csrfToken}
		})
		axInstance.delete('/api/ezp/v2/user/sessions/' + sessionId)
			.then(() => callback())
			.catch(() => callback());
	}

	submitContactForm(identifier, data) {
		return this.axiosInstance.post('/api/' + identifier + '/contact_form', data);
	}

	_doGetRequest(path, tenantId, callback) {
		const config = {};
		if (window.lang) {
			config.headers = {
				'x-siteaccess': window.lang,
			}
		}

		this.axiosInstance.get('/api/' + tenantId + path, config)
			.then((response) => {
				const responseData = response.data;
				if (!responseData && responseData !== 0) {
					alert('Unable to load data');
					return;
				}
				callback(responseData);
			})
			.catch((error) => {
				alert(error);
			});
	}
}
