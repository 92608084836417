import adminStore from "@/store/adminStore";

import { createStore } from 'vuex'
import { ACTION_TYPES, KEYS, MUTATION_TYPES } from "@/constants";
import RequestHandler from "@apiClient/RequestHandler";
import LocalStorageHandler from "@/api/LocalStorageHandler";

const state = () => ({
    tenantId: null,
    domainTenantId: null,
    domainTenantLoaded: false,
    data: null,
    dataLoading: false,
    terms: null,
    termsLoading: false,
    about: null,
    aboutLoading: false,
    contact: null,
    contactLoading: false,
    currentCategory: 'all',
    cartItems: [],
    reservationFetchParams: null,
    reservation: null,
    reservationLoading: false,
    reservationLoaded: false,
    selectedOptions: {
        productId: null,
        options: {},
    }
});

const actions = {
    [ACTION_TYPES.loadMainData]: (context) => {
        if (!context.getters.tenantId && !context.getters.domainTenantId) {
            return;
        }
        context.commit(MUTATION_TYPES.setDataLoading, true);
        RequestHandler.fetchData(context.getters.tenantId || context.getters.domainTenantId, (data) => {
            context.commit(MUTATION_TYPES.setData, data);
            context.commit(MUTATION_TYPES.setDataLoading, false);
        });
    },
    [ACTION_TYPES.loadTerms]: (context) => {
        if (!context.getters.tenantId || context.getters.domainTenantId) {
            return;
        }
        context.commit(MUTATION_TYPES.setTermsLoading, true);
        RequestHandler.fetchTerms(context.getters.tenantId || context.getters.domainTenantId, (data) => {
            context.commit(MUTATION_TYPES.setTerms, data.content || null);
            context.commit(MUTATION_TYPES.setTermsLoading, false);
        });
    },
    [ACTION_TYPES.loadAbout]: (context) => {
        if (!context.getters.tenantId && !context.getters.domainTenantId) {
            return;
        }
        context.commit(MUTATION_TYPES.setAboutLoading, true);
        RequestHandler.fetchAbout(context.getters.tenantId || context.getters.domainTenantId, (data) => {
            context.commit(MUTATION_TYPES.setAbout, data.content || null);
            context.commit(MUTATION_TYPES.setAboutLoading, false);
        });
    },
    [ACTION_TYPES.loadContact]: (context) => {
        if (!context.getters.tenantId && !context.getters.domainTenantId) {
            return;
        }
        context.commit(MUTATION_TYPES.setContactLoading, true);
        RequestHandler.fetchContact(context.getters.tenantId || context.getters.domainTenantId, (data) => {
            context.commit(MUTATION_TYPES.setContact, data);
            context.commit(MUTATION_TYPES.setContactLoading, false);
        });
    },
    [ACTION_TYPES.loadReservation]: (context) => {
        context.commit(MUTATION_TYPES.setReservationLoading, true);
        const reservationFetchParams = context.getters.reservationFetchParams;

        if (!reservationFetchParams.confirmationNumber || !reservationFetchParams.securityHash) {
            context.commit(MUTATION_TYPES.setReservationLoading, false);
            context.commit(MUTATION_TYPES.setReservationLoaded, true);
            return;
        }

        RequestHandler.fetchReservation(reservationFetchParams, (data) => {
            context.commit(MUTATION_TYPES.setReservation, data);
            context.commit(MUTATION_TYPES.setReservationLoading, false);
            context.commit(MUTATION_TYPES.setReservationLoaded, true);
        });
    },
    [ACTION_TYPES.clearCart]: (context) => {
        context.commit(MUTATION_TYPES.setCartItems, []);
        context.commit(MUTATION_TYPES.syncStorage);
    },
    [ACTION_TYPES.addItemToCart]: (context, payload) => {
        context.commit(MUTATION_TYPES.pushItemToCart, {
            voucher: payload.voucher,
            option: payload.option,
            checkoutInfo: {}
        });
    },
    [ACTION_TYPES.updateTenant]: (context, payload) => {
        if (payload) {
            context.commit(MUTATION_TYPES.setCartItems, LocalStorageHandler.get(KEYS.cartStorageKey + payload) || []);
            context.commit(
                MUTATION_TYPES.setReservationFetchParams,
                LocalStorageHandler.get(KEYS.reservationFetchParamsStorageKey) || {}
            );
            context.commit(MUTATION_TYPES.setTenantId, payload);
        }
    },
    [ACTION_TYPES.loadDomainTenant]: (context) => {
        if (typeof window === 'undefined') {
            return;
        }
        const domain = window.location.hostname;
        RequestHandler.fetchDomainTenant(domain)
            .then(({ data }) => {
                if (data && data.identifier) {
                    context.commit(MUTATION_TYPES.setCartItems, LocalStorageHandler.get(KEYS.cartStorageKey + data.identifier) || []);
                    context.commit(
                        MUTATION_TYPES.setReservationFetchParams,
                        LocalStorageHandler.get(KEYS.reservationFetchParamsStorageKey) || {}
                    );
                    context.commit(MUTATION_TYPES.setDomainTenantId, data.identifier);
                }
                context.commit(MUTATION_TYPES.setDomainTenantLoaded, true);
            })
            .catch(() => {
                context.commit(MUTATION_TYPES.setDomainTenantLoaded, true);
            });
    },
    [ACTION_TYPES.storeReservationFetchParams]: (context, payload) => {
        context.commit(MUTATION_TYPES.setReservationFetchParams, payload);
        context.commit(MUTATION_TYPES.syncStorage);
    },
    [ACTION_TYPES.syncWithStorage]: (context) => context.commit(MUTATION_TYPES.syncStorage),
};

function syncStorage(state) {
    LocalStorageHandler.set(KEYS.cartStorageKey, state.cartItems);
    LocalStorageHandler.set(KEYS.reservationFetchParamsStorageKey, state.reservationFetchParams);
}

const mutations = {
    [MUTATION_TYPES.setReservationFetchParams]: (state, data) => {
        state.reservationFetchParams = {
            securityHash: data.securityHash || null,
            confirmationNumber: data.confirmationNumber || null
        }
    },
    [MUTATION_TYPES.setTenantId]: (state, data) => state.tenantId = data,
    [MUTATION_TYPES.setDomainTenantId]: (state, data) => state.domainTenantId = data,
    [MUTATION_TYPES.setDomainTenantLoaded]: (state, data) => state.domainTenantLoaded = data,
    [MUTATION_TYPES.setTenantId]: (state, data) => state.tenantId = data,
    [MUTATION_TYPES.setData]: (state, data) => state.data = data,
    [MUTATION_TYPES.setDataLoading]: (state, data) => state.dataLoading = data,
    [MUTATION_TYPES.setTerms]: (state, data) => state.terms = data,
    [MUTATION_TYPES.setTermsLoading]: (state, data) => state.termsLoading = data,
    [MUTATION_TYPES.setAbout]: (state, data) => state.about = data,
    [MUTATION_TYPES.setAboutLoading]: (state, data) => state.aboutLoading = data,
    [MUTATION_TYPES.setContact]: (state, data) => state.contact = data,
    [MUTATION_TYPES.setContactLoading]: (state, data) => state.contactLoading = data,
    [MUTATION_TYPES.setReservationLoading]: (state, data) => state.reservationLoading = data,
    [MUTATION_TYPES.setReservationLoaded]: (state, data) => state.reservationLoaded = data,
    [MUTATION_TYPES.setReservation]: (state, data) => state.reservation = data,
    [MUTATION_TYPES.setCurrentCategory]: (state, data) => state.currentCategory = data,
    [MUTATION_TYPES.setCartItems]: (state, data) => state.cartItems = data,
    [MUTATION_TYPES.syncStorage]: (state) => syncStorage(state),
    [MUTATION_TYPES.pushItemToCart]: (state, data) => {
        state.cartItems.push(data);
        state.selectedOptions = {
            productId: null,
            options: {},
        };
        syncStorage(state);
    },
    [MUTATION_TYPES.setProductOption]: (state, data) => {
        if (state.selectedOptions.productId !== +data.productId || !data.option) {
            state.selectedOptions.options = {};
        }
        state.selectedOptions.productId = +data.productId;
        if (data.option) {
            if (data.index) {
                state.selectedOptions.options[+data.index] = data.option;
            } else {
                let newOptions = {};
                let nextIndex = 1;
                Object.values(state.selectedOptions.options).forEach((option) => {
                    newOptions[nextIndex] = option;
                    nextIndex++;
                });
                newOptions[nextIndex] = data.option;
                state.selectedOptions.options = newOptions;
            }
        }

        syncStorage(state);
    },
    [MUTATION_TYPES.removeItemFromCart]: (state, data) => {
        state.cartItems = state.cartItems.filter((item) =>
            item.voucher.id !== data.id || item.option !== data.option
        );
        syncStorage(state);
    },
    [MUTATION_TYPES.decreaseItemCount]: (state, data) => {
        let index;
        state.cartItems.forEach((item, key) => {
            if (item.voucher.id === data.id && item.option === data.option) {
                index = key;
            }
        });

        if (index !== undefined) {
            state.cartItems.splice(index, 1);
        }

        syncStorage(state);
    }
};

const getters = {
    tenantId: (state) => state.tenantId,
    domainTenantId: (state) => state.domainTenantId,
    domainTenantLoaded: (state) => state.domainTenantLoaded,
    vouchers: (state) => state.data.vouchers,
    filteredVouchers: (state) => {
        if (!state.data) {
            return null;
        }
        return state.currentCategory !== 'all'
            ? state.data.vouchers.filter(voucher => voucher.categories.indexOf(state.currentCategory) !== -1)
            : state.data.vouchers;
    },
    featuredProducts: (state) => {
        if (!state.data) {
            return null;
        }
        return state.data.vouchers.filter(voucher => voucher.featured > 0).sort((a, b) => {
            if (a.featured === b.featured) {
                return 0;
            }
            return a.featured > b.featured ? 1 : -1;
        });
    },
    isDataLoading: (state) => state.dataLoading,
    terms: (state) => state.terms,
    isTermsLoading: (state) => state.termsLoading,
    about: (state) => state.about,
    isAboutLoading: (state) => state.aboutLoading,
    contact: (state) => state.contact,
    isContactLoading: (state) => state.contactLoading,
    categories: (state) => state.data ? state.data.categories : null,
    currentCategory: (state) => state.currentCategory,
    data: (state) => state.data,
    groupedCartItems: (state) => {
        let items = [];
        if (!state.cartItems || !state.cartItems.length) {
            return items;
        }
        state.cartItems.forEach((item) => {
            const sameItem = items.find((groupedItem) => {
                    return groupedItem.data.voucher.id === item.voucher.id && groupedItem.data.option === item.option
                }
            );
            if (!sameItem) {
                items.push({ data: item, count: 1 })
                return;
            }
            sameItem.count++;
        })

        return items;
    },
    cartItems: (state) => state.cartItems,
    cartItemsCount: (state) => state.cartItems ? state.cartItems.length : 0,
    paymentInfo: (state) => state.paymentInfo,
    reservationFetchParams: (state) => state.reservationFetchParams,
    reservation: (state) => state.reservation,
    reservationLoading: (state) => state.reservationLoading,
    reservationLoaded: (state) => state.reservationLoaded,
    selectedOptions: (state) => state.selectedOptions,
};

export default createStore({ modules: { adminStore }, state, actions, mutations, getters });