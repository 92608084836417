<template>
  <form class="form">
    <div class="card__label">{{ label }}</div>
    <div class="card__select custom-select">
      <select v-bind:name="name">
        <option value="" default="default">Select</option>
        <option v-for="(itemCode, item) in items"
                v-bind:value="itemCode"
                v-bind:key="itemCode"
        >
          {{ item }}
        </option>
      </select>
      <div v-text="value && items[value] ? items[value] : 'Select'"
           v-bind:class="['select-selected openButton', {'select-arrow-active': opened}]"
           v-on:click="opened = !opened"
      ></div>
      <div v-bind:class="['select-items custom-select--short', {'select-hide': !opened}]"
           v-closable="{
              exclude: ['openButton'],
              handler: 'outsideClick'
            }"
      >
        <div v-for="(itemCode, item) in items"
             v-bind:key="itemCode"
             v-on:click="select(item)"
        >
          {{ itemCode }}
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {ref} from 'vue'

export default {
  name: 'voucher-custom-select',
  model: {
    prop: 'value',
    event: 'selected'
  },
  setup(props, {emit}) {
    const select = value => {
      opened.value = false;
      emit('update:value', value)
    }
    const opened = ref(false);

    const outsideClick = () => {
      opened.value = false;
    }
    return {select, outsideClick, opened}
  },
  props: {
    value: {
      type: [String, Number],
    },
    name: String,
    label: String,
    items: {
      type: [Array, Object],
      required: true
    },
  }
};
</script>

<style>
  .custom-select .select-items.custom-select--short {
    max-height: 235px;
  }
</style>