<template>
  <svg width="0" height="0" class="hidden">
    <symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" id="i-back">
      <g transform="translate(4 4)">
        <path d="M3.414 9l5.293 5.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 1.414L3.414 7H15a1 1 0 010 2H3.414z"></path>
      </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="i-cart">
      <g>
        <path d="M9 23a2 2 0 110-4 2 2 0 010 4zm11 0a2 2 0 110-4 2 2 0 010 4zM4.18 2H1a1 1 0 110-2h4a1 1 0 01.98.804L6.82 5H23a1 1 0 01.982 1.187l-1.601 8.398A2.995 2.995 0 0119.4 17H9.7a3 3 0 01-3-2.414L5.028 6.24a.995.995 0 01-.017-.084L4.18 2zm3.04 5l1.44 7.195c.095.475.517.814 1.02.805h9.74c.483.01.905-.33.998-.797L21.79 7H7.221z"></path>
      </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" id="i-delete">
      <path d="M7 5V4a3 3 0 013-3h4a3 3 0 013 3v1h4a1 1 0 010 2h-1v13a3 3 0 01-3 3H7a3 3 0 01-3-3V7H3a1 1 0 010-2h4zm2 0h6V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v1zm9 2H6v13a1 1 0 001 1h10a1 1 0 001-1V7zm-9 4a1 1 0 012 0v6a1 1 0 01-2 0v-6zm4 0a1 1 0 012 0v6a1 1 0 01-2 0v-6z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" id="i-logout">
      <defs>
        <path d="M16.586 11H7a1 1 0 010-2h9.586l-3.293-3.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L16.586 11zM7 18a1 1 0 010 2H3a3 3 0 01-3-3V3a3 3 0 013-3h4a1 1 0 110 2H3a1 1 0 00-1 1v14a1 1 0 001 1h4z" id="a"></path>
      </defs>
      <g fill-rule="evenodd">
        <use fill-rule="nonzero" xlink:href="#a" transform="translate(2 2)"></use>
      </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="i-cart_checked">
      <path d="M9 23a2 2 0 110-4 2 2 0 010 4zm11 0a2 2 0 110-4 2 2 0 010 4zM4.18 2H1a1 1 0 110-2h4a1 1 0 01.98.804l2.68 13.391c.095.475.517.814 1.02.805h9.74a1 1 0 001-.805l.026-.105 1.6-5.096a1 1 0 011.908.599l-1.585 5.05A2.994 2.994 0 0119.4 17H9.7a3 3 0 01-3-2.414L4.18 2zm15.589-.147a1 1 0 011.509 1.312l-6.523 7.501a1 1 0 01-1.462.051l-3-3a1 1 0 011.414-1.414l2.242 2.242 5.82-6.692z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="i-card">
      <path d="M2 9h20V6a1 1 0 00-1-1H3a1 1 0 00-1 1v3zm0 2v7a1 1 0 001 1h18a1 1 0 001-1v-7H2zm1-8h18a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V6a3 3 0 013-3z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="i-checkstep">
      <path d="M21 11.08a1 1 0 012 0V12a11 11 0 11-6.523-10.053 1 1 0 01-.814 1.826A9 9 0 1021 12v-.92zm.293-7.787a1 1 0 011.414 1.414l-10 10.01a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L12 12.595l9.293-9.302z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459 459" id="i-check-input">
      <path d="M408 51v357H51V51h357m0-51H51C23 0 0 23 0 51v357c0 28 23 51 51 51h357c28 0 51-23 51-51V51c0-28-23-51-51-51z"/>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459 459" id="i-checked-input">
      <path d="M125 181l-36 36 115 115L459 77l-36-36-219 219-79-79zm283 227H51V51h255V0H51C23 0 0 23 0 51v357c0 28 23 51 51 51h357c28 0 51-23 51-51V204h-51v204z"/>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 13" id="i-checked">
      <path d="M6 10.586L16.293.293a1 1 0 011.414 1.414l-11 11a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L6 10.586z" ></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" id="i-eye">
      <g transform="translate(0 3)">
        <path id="a" d="M2.574 9.707c.575.89 1.254 1.781 2.03 2.61C6.777 14.631 9.255 16 12 16s5.224-1.368 7.395-3.684A18.402 18.402 0 0021.859 9a18.614 18.614 0 00-2.463-3.317C17.223 3.369 14.745 2 12 2S6.776 3.368 4.605 5.684A18.402 18.402 0 002.141 9c.126.218.27.455.433.707zM.106 8.553c.14-.281.404-.75.788-1.346a20.514 20.514 0 012.251-2.89C5.661 1.631 8.62 0 12 0c3.38 0 6.339 1.632 8.855 4.316a20.496 20.496 0 012.25 2.891c.385.596.649 1.065.79 1.346.14.281.14.613 0 .894-.141.281-.405.75-.79 1.346a20.432 20.432 0 01-2.25 2.89C18.339 16.369 15.38 18 12 18c-3.38 0-6.339-1.632-8.855-4.316a20.496 20.496 0 01-2.25-2.891 15.36 15.36 0 01-.79-1.346 1.004 1.004 0 010-.894zM12 13c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-2a2 2 0 10.001-3.999A2 2 0 0012 11z"></path>
      </g>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" id="i-info">
      <g transform="translate(6 6)">
        <path id="a" d="M6 11.5a5.5 5.5 0 110-11 5.5 5.5 0 110 11zm0-1a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM6.5 8a.5.5 0 01-1 0V6a.5.5 0 011 0v2zm-1-4a.5.5 0 01.5-.5.5.5 0 11.005 1A.501.501 0 015.5 4z"></path>
      </g>
    </symbol>
  </svg>
  <!-- SVG Sprite -->
  <header class="header" v-if="!loading" v-bind:class="{'sticky-header': scrollY > 0}">
    <div class="site-width">
      <div class="header__left" v-bind:class="{'header__left--empty': routHierarchy[routeName] === 'none'}">
        <a class="back__link btns" href="#" v-on:click.prevent="goBack" v-if="routHierarchy[routeName] !== 'none'">
          <svg class="icon i24x24">
            <use xlink:href="#i-back"></use>
          </svg>
        </a>
      </div>
      <div class="header__logo" v-if="!data.hideHeaderLogo">
        <router-link v-bind:to="{name: isAdmin ? 'admin_search' : 'home', params: {lang} }" class="logo__link images__link" title="Home">
          <img class="logo" v-bind:src="data.logo.src" v-bind:alt="data.logo.name">
        </router-link>
      </div>
      <div class="header__right">
        <logout-header-link v-if="isAdmin"></logout-header-link>
        <router-link v-bind:to="{'name': 'cart', params: {lang}}"
                     v-bind:class="['cart__link ', {'active': cartItemsCount > 0}]"
                     v-bind:title="'Cart'"
                     v-else
        >
          <span class="cart__quantity" v-if="cartItemsCount > 0">{{ cartItemsCount }}</span>
          <svg class="icon i24x24">
            <use xlink:href="#i-cart"></use>
          </svg>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, onUnmounted, ref, watchEffect} from 'vue'
import {useRouter, useRoute} from "vue-router";
import {ACTION_TYPES} from "@/constants";
import LogoutHeaderLink from "@/components/admin/LogoutHeaderLink";

export default {
  name: 'header-line',
  components: {LogoutHeaderLink},
  setup() {
    const store = useStore();
    const cartItemsCount = computed(() => store.getters.cartItemsCount);
    const data = computed(() => store.getters.data);
    const loading = ref(false);
    const scrollY = ref(0);

    const updateScrole = () => scrollY.value = window.scrollY;

    onMounted(() => {
      window.addEventListener('scroll', updateScrole);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', updateScrole);
    });

    watchEffect(() => loading.value = !data.value);

    const routHierarchy = {
      home: 'none',
      voucher: 'home',
      cart: 'shop',
      checkout: 'cart',
      payment: 'checkout',
      terms: 'home',
      confirmation: 'none',
      admin_search: 'none',
      admin_login: 'none',
      admin_voucher: 'admin_search',
      admin_redeem: 'back',
      not_found: 'home',
    };
    const router = useRouter();
    const route = useRoute();
    const goBack = () => {
      store.dispatch(ACTION_TYPES.syncWithStorage);
      const nextRout = routHierarchy[route.name];
      if (!nextRout || nextRout === 'back') {
        router.back();
        return;
      }
      router.push({name: nextRout, params: route.params});
    };

    return {
      cartItemsCount,
      goBack,
      data,
      loading,
      scrollY,
      routHierarchy,
      routeName: route.name,
      isAdmin: route.meta.adminPage
    };
  }
};
</script>

<style lang="scss">
@import "../scss/mixins";

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #ffffff, #f5f7f8);
  z-index: 4;

  .site-width {
    @include flex_justify_align(row, space-between, center);
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__left {
    min-width: 24px;

    .back__link {
      opacity: 0.54;
      fill: #485053;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__logo {
    flex: 1 0 auto;
    height: 48px;

    .logo__link {
      display: block;
      height: 100%;
      text-align: center;
    }

    .logo {
      width: auto;
      height: 100%;
    }
  }

  &__right {
    .cart__link {
      text-decoration: none;

      .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        opacity: 0.54;
        fill: #485053;
        vertical-align: middle;
      }

      &.active {
        .icon {
          fill: #f5a623;
          opacity: 1;
        }
      }

      span {
        display: inline-block;
        width: 15px;
        height: 15px;
        font-size: 11px;
        font-weight: 500;
        color: #fff;
        line-height: 15px;
        text-align: center;
        background-color: #f5a623;
        border-radius: 50%;
        vertical-align: middle;
      }
    }

    .logout__link {
      opacity: 0.54;

      .icon {
        fill: #000;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &.sticky-header {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 768px) {
  .header {
    &__logo {
      height: 64px;
      margin: 0 30px;
      padding-bottom: 4px;
    }
  }

}
</style>